<template>
  <div class="container-fluid">
    <div class="container p-tb-2">
      <div v-if="products.length" class="slider-handler">
        <div v-if="!isSmall" class="slider-nav">
          <VueSlickCarousel v-bind="sliderNavSettings" ref="sliderNav">
            <template v-for="(item, i) in products">
              <div class="nav-slide" :key="i">
                <div class="slide-image" :style="`background-image: url(${item.image})`" />
              </div>
            </template>
          </VueSlickCarousel>
        </div>
        <div class="slider-main slick-slider-wrapper">
          <div class="heading text-center large-font text-bold">
            <span v-if="current">{{ current.title }}</span>
          </div>
          <VueSlickCarousel v-bind="sliderSettings" ref="slider" @afterChange="afterSlideChange">
            <template v-for="(item, i) in products">
              <div class="slide" :key="i">
                <div class="slide-image" :style="`background-image: url(${item.image})`" />
              </div>
            </template>
          </VueSlickCarousel>
          <div class="controls">
            <button class="button uppercase text-color-white bg-light-green rounded-5" @click="customize">
              Build your own design
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FP_TYPES_AND, FP_TYPES_OR } from 'Lib/enums/Filters';
import { TYPE_IN_STOCK, TYPE_PDC_FRONT, TYPE_PDC_REAR, TYPE_SAMPLES_IN_STOCK } from 'Lib/enums/Types';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

const COMMON_SLIDER_PROPS = {
  autoplay: false,
  autoplaySpeed: 0,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  swipeToSlide: true,
  focusOnSelect: true,
};

export default {
  name: 'Customization',
  components: {
    VueSlickCarousel,
  },
  methods: {
    afterSlideChange(index) {
      this.currentSlide = index;
    },
    customize() {
      if (this.current) {
        this.$router.push(`/constructor/${this.current.slug}`);
      }
    },
  },
  computed: {
    current() {
      return this.products[this.currentSlide] || null;
    },
    isSmall() {
      return window.innerWidth <= 640;
    },
  },
  data() {
    return {
      collection: null,
      products: [],
      sliderSettings: {
        ...COMMON_SLIDER_PROPS,
        slidesToShow: 1,
        arrows: true,
        asNavFor: null,
      },
      sliderNavSettings: {
        ...COMMON_SLIDER_PROPS,
        arrows: false,
        vertical: true,
        slidesToShow: 3,
        verticalSwiping: true,
        asNavFor: null,
      },
      currentSlide: 0,
    };
  },
  created() {
    this.$api
      .getProductsCollection(
        {
          [FP_TYPES_OR]: [TYPE_IN_STOCK, TYPE_SAMPLES_IN_STOCK],
          [FP_TYPES_AND]: [TYPE_PDC_FRONT, TYPE_PDC_REAR],
          not_types: [66, 135, 136],
          itemsPerPage: 32,
        },
        !this.$store.getters.isLoggedIn
      )
      .then(coll => {
        this.collection = coll;
        this.products = coll.items;
      });
    this.$store.dispatch('setBreadcrumbs', [{ to: this.$route.path, title: 'Customization' }]);
  },
  updated() {
    if (!this.isSmall) {
      const { slider, sliderNav } = this.$refs;
      if (slider && sliderNav) {
        this.sliderNavSettings.asNavFor = slider;
        this.sliderSettings.asNavFor = sliderNav;
      }
    }
  },
};
</script>

<style type="text/css" lang="scss">
.slider-handler {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  --slider-size: 480px;
  --slide-size: calc((var(--slider-size) / 3) - 1px);

  .heading {
    min-height: 42px;
  }

  .slider-nav {
    width: calc(var(--slide-size) + 1px);
    margin: 15px;
    padding: 0 15px;

    .nav-slide {
      max-width: var(--slide-size);
      height: var(--slide-size);
      box-sizing: border-box;
      border: 1px solid #dedede;
      border-radius: 3px;
    }
  }

  .slider-main {
    display: flex;
    flex-direction: column;
    width: var(--slider-size);
    margin: 15px;

    .slick-slider {
      margin: 10px 0 35px 0;
    }

    .slide {
      width: var(--slider-size);
      height: var(--slider-size);
    }
  }
  .slide-image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media only screen and (max-width: 640px) {
  .slider-handler {
    --slider-size: 80vw;
  }
  .slider-nav {
    display: none;
  }
}
</style>
